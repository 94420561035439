$color-main: #fb0206;
$black: #272727;

.WeaverImage{
  width: 260px; /* Set the desired width for your logo */
  height: auto; /* Maintain aspect ratio */
  align-items: center; /* Center vertically */
  border-radius: 10px 10px 10px 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.weaver-details-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  background-color: $color-main; /* Button background color */
  color: #ffffff; /* Button text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  margin-top: 5px;
}

.detail-birina {
  font-size: 25px;
  font-weight: 700;
  text-align: right;
  display: inline-block;
  position: relative;
  color: $color-main;
  margin-bottom: 0px;
}

.detail-birina-black {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: $black;
  display: inline-block;
  position: relative;
  margin-top: 5px;
}

.detail-birina-gamosa {
  font-size: 12px;
  padding: 5px 20px 0px 20px;
  font-weight: 500;
  text-align: center;
  color: $black;
  display: inline-block;
  position: relative;
  margin-top: -10px;
}
