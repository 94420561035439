.page {
  position: absolute;
  width: 100%;
  height: 100%;
  will-change: transform;
}

.page > div {
  touch-action: none;
  border-radius: 20px;
  background-color: #fff;
  width: 100%;
  height: 100%;
  will-change: transform;
  background-image: url("../public/map.png");
  background-repeat: no-repeat;
  background-position-y: 100%;
  background-size: contain;
  box-shadow: 0 62.5px 125px -25px rgba(50, 50, 73, 0.5), 0 37.5px 75px -37.5px rgba(0, 0, 0, 0.6);
}

.container {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.wrapper {
  width: 100%;
  height: 100%;
}