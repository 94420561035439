
$color-main: #fb0206;
$black: #272727;

.weaver-container{
  display: flex;
  flex-direction: column;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content if needed */
  width: 100vw;
  height: 100vh;
  text-align: center;
  position: relative;
  background-image: url("../../../public/phoolam-gamosa-1.png");
  background-repeat: no-repeat;
  background-position-y: 0%, 42%;
  background-size: contain;

}

.weaver {
  margin-top: -5px;
  margin-left: 150px;
  font-size: 15px;
  font-weight: 900;
  text-align: right;
  color: $black;
  display: inline-block;
  position: relative;
  font-family: Sans-serif;
  margin-bottom: 5px;
}

.weaver-send {
  margin-top: -100px;
  margin-left: 150px;
  font-size: 15px;
  font-weight: 900;
  text-align: right;
  color: $black;
  display: inline-block;
  position: relative;
  font-family: Sans-serif;
  margin-bottom: 5px;
  color: #fb0206;
}

.weaver-box{
  width: 100%;
  margin-top: -130px;
}


.weaver-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 12px;
  margin-left: 150px; // ToDo- change this to center
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  background-color: $color-main; /* Button background color */
  color: #ffffff; /* Button text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  margin-top: 5px;
}

.weaver-button:hover {
  background-color: #8e0103; /* Button background color on hover */
}