$color-main: #fb0206;
$black: #272727;

.logo {
  width: 150px;
  /* Set the desired width for your logo */
  display: flex;
  align-items: center;
  /* Align items vertically in the center */
  height: auto;
  /* Maintain aspect ratio */
  will-change: filter;
  transition: filter 300ms;
}

.logo:hover {
  filter: drop-shadow(0 0 2em #fb0206);
}

.logo.react:hover {
  filter: drop-shadow(0 0 2em #fb0206);
}

.logo-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  /* Adjust the margin between logo and text */
}

.landing-container {
  font-size: 40px;
  font-weight: 700;
  text-align: right;
  color: $black;
  display: inline-block;
  position: relative;
}

.landing-birina {
  font-size: 40px;
  font-weight: 700;
  text-align: right;
  color: $black;
  display: inline-block;
  position: relative;
  margin-top: -50px;
  color: $color-main;
}

.landing-swipe {
  font-size: 20px;
  font-weight: 500;
  text-align: right;
  color: $black;
  display: inline-block;
  position: relative;
}

.landing-lw3 {
  font-size: 10px;
  font-weight: 500;
  text-align: left;
  color: $black;
  display: inline-block;
  position: relative;
}

.text-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}


.landing {
  display: flex;
  flex-direction: column;

  p,
  img {
    opacity: 0;
  }

  &.animate-in {

    p,
    img {
      animation: textHidden 0.1s 1.1s forwards;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      z-index: 10;
    }

    &:before {
      background-color: $color-main;
    }

    &:after {
      background-color: $black;
      animation-delay: .5s;
    }
  }

  &.animation-ltr.animate-in {
    &:before {
      animation: revealLTR 1.8s ease;
    }

    &:after {
      animation: revealLTR 1s .6s ease;
    }
  }

  &.animation-rtl.animate-in {
    &:before {
      animation: revealRTL 1.8s ease;
    }

    &:after {
      animation: revealRTL 1s .6s ease;
    }
  }
}

@keyframes revealRTL {
  0% {
    width: 0;
    right: 0;
  }

  65% {
    width: 100%;
    right: 0;
  }

  100% {
    width: 0;
    right: 100%;
  }
}

@keyframes revealLTR {
  0% {
    width: 0;
    left: 0;
  }

  65% {
    width: 100%;
    left: 0;
  }

  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes textHidden {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}