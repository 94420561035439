$color-main: #fb0206;
$black: #272727;



.location{
    display: flex;
    flex-direction: column;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content if needed */
    width: 100vw;
    height: 100vh;
    text-align: center;
    position: relative;
    background-image: url("../../../public/phoolam-gamosa-1.png");
    background-repeat: no-repeat;
    background-position-y: 0%;
    background-size: contain;

}

.mapLocation {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  margin-top: -50px;
  text-align: center;
  color: $black;
  display: inline-block;
  position: relative;
  font-family: Sans-serif;
}
.mapLocationNFT{
    margin: 0;
    font-size: 30px;
    font-weight: 570;
  
    text-align: center;
    color: $black;
    display: inline-block;
    position: relative;
    margin-bottom: 20px;
    font-family: Sans-serif;    
    color: #fb0206;
}
    
.has-animation-map {
    p, img {
        opacity: 0;
    }
    &.animate-in {
    p, img {
        animation: textHidden 0.1s 1.1s forwards;
    }
    &:before, &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        z-index: 10;
    }
    &:before {
        background-color: $color-main;
    }
    &:after {
        background-color: $black;
        animation-delay: .5s;
    }
    }
    &.animation-ltr.animate-in {
    &:before {
        animation: revealLTR 1.8s ease;
    }
    &:after {
        animation: revealLTR 1s .6s ease;
    }
    }
    &.animation-rtl.animate-in {
    &:before {
        animation: revealRTL 1.8s ease;
    }
    &:after {
        animation: revealRTL 1s .6s ease;
    }
    }
}

@keyframes revealRTL {
    0%{
    width: 0;
    right: 0;
    }
    65%{
    width: 100%;
    right: 0;
    }
    100% {
    width: 0;
    right: 100%;
    }
}

@keyframes revealLTR {
    0%{
    width: 0;
    left: 0;
    }
    65%{
    width: 100%;
    left: 0;
    }
    100% {
    width: 0;
    left: 100%;
    }
}

@keyframes textHidden {
    0% {
    opacity: 0;
    }
    100% {
    opacity: 1;
    }
}