
$color-main: #fb0206;
$black: #272727;

.gamosa-container{
  display: flex;
  flex-direction: column;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content if needed */
  width: 100vw;
  height: 100vh;
  text-align: center;
  position: relative;
  background-image: url("../../../public/phoolam-gamosa-1.png"), url("../../../public/kopou-asli.png");
  background-repeat: no-repeat;
  background-position-y: 0%, 35%;
  background-size: contain;
}

.gamosa-h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: $black;
  display: inline-block;
  position: relative;
  font-family: Sans-serif;
  margin-bottom: 5px;
  margin-top: -20px;
}
.gamosa-h2{
    margin: 0;
    font-size: 30px;
    font-weight: 900;
  
    text-align: center;
    color: $black;
    display: inline-block;
    position: relative;
    
    font-family: Sans-serif;    
    color: #fb0206;
}
.gamosa-assamese{
  margin: 0;
  font-size: 30px;
  font-weight: 700;

  text-align: center;
  color: $black;
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
  font-family: Sans-serif;    
}
